import React from 'react'
import PropTypes from 'prop-types'
import fetchApi from '../services/fetchApi'
import './styles/social-links.css'

const types = ['facebook', 'google', 'yelp']

const SocialLinks = ({ urls }) => {
  const handleOnClick = (site) => {
    postSiteClicked(site)
    const url = urls[`${site}_url`]
    window.open(url)
    return
  }

  const postSiteClicked = (site) => {
    const appointmentId = document.location.pathname.split("/")[1]
    const body = JSON.stringify({ site })
    return fetchApi(`/review/${appointmentId}/clicked`, 'POST', body)
  }

  const renderIcon = (type) => {
    if (!urls || !urls[`${type}_url`]) return
    return (
      <button onClick={() => handleOnClick(type)}>
        <img src={`${type}.svg`} alt={type} />
      </button>
    )
  }

  return (
    <div className="socialLinks">
      {types.map(renderIcon)}
    </div>
  )
}

SocialLinks.propTypes = {
  urls: PropTypes.shape({
    facebook_url: PropTypes.string,
    google_url: PropTypes.string,
    yelp_url: PropTypes.string,
  }),
}

export default SocialLinks
