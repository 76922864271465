const {
  REACT_APP_API_BASE_URL,
  REACT_APP_API_TOKEN
} = process.env
const DESTRUCTIVE_HTTP_VERBS = new Set(['POST', 'PUT', 'PATCH', 'DELETE'])

const unwrapApiErrorMessages = (body) => {
  if (!body.error) {
    return ''
  } else if (typeof body.error === 'string') {
    return body.error
  }

  return Object.keys(body.error)
    .map((k) => {
      const reasons = body.error[k].join(', ')
      return `${k}: ${reasons}`
    })
}

/**
 * @param body, {Object} JSON-parsed body of API response
 * @param response, {Response} meta-data of API response
 *   see https://developer.mozilla.org/en-US/docs/Web/API/Response
 */
const createApiError = (body, response) => {
  const {
    status, statusText, headers, ok, type,
  } = response
  const message = unwrapApiErrorMessages(body)

  return {
    status,
    statusText,
    ok,
    type,
    headers,
    body,
    message,
  }
}

const wrapError = response => (
  response.json()
    .then((body) => {
      const apiError = createApiError(body, response)
      return Promise.reject(apiError)
    })
)

const generateDefaultHeaders = () => {
  const headers = new Headers()
  const token = REACT_APP_API_TOKEN
  headers.append('Authorization', token)
  headers.append('Content-Type', 'application/json')
  return headers
}

const configureRequest = (verb, body) => {
  const headers = generateDefaultHeaders()
  const config = {
    method: verb,
    headers,
  }
  if (DESTRUCTIVE_HTTP_VERBS.has(verb)) { config.body = body }

  return config
}

export default (route, verb, body) => {
  const url = `${REACT_APP_API_BASE_URL}${route}`
  const config = configureRequest(verb, body)
  return fetch(url, config)
    .then((response) => {
      if (response.status === 401 || !response.ok) {
        return wrapError(response)
      }
      return response.status === 204 ? // no response body causes problems
      {} :
        response.json()
    })
}
