import React, { useEffect, useState } from 'react'
import fetchApi from '../services/fetchApi'
import SocialLinks from './SocialLinks'
import CommentBox from './CommentBox'
import './App.css'

const App = () => {
  const [positive, setPositive] = useState(null)
  const [urls, setUrls] = useState(null)
  const [existingComment, setExistingComment] = useState(null)
  const [appointmentExists, setAppointmentExists] = useState(false)

  useEffect(() => {
    determinePositivity()
    if (positive === null) return
    postReview()
  }, [positive])

  const postReview = async () => {
    const body = buildPostBody()
    const appointmentId = document.location.pathname.split('/')[1]
    const response = await fetchApi(`/review/${appointmentId}`, 'POST', body)

    setAppointmentExists(true)
    if (response.urls) setUrls(response.urls)
    if (response.review.comment) {
      setExistingComment(response.review.comment)
    }
  }

  const buildPostBody = () => {
    const body = {}
    const rating = determinePositivity()
    if (rating === null) return null
    if (Number.isInteger(Number(rating))) {
      body.stars = rating
    } else {
      body.rating = rating
    }
    return JSON.stringify(body)
  }

  const determinePositivity = () => {
    const rating = document.location.search.split("=")[1]
    if (!rating) return
    if (rating === 'positive' || rating > 3) {
      setPositive(true)
    } else if (rating === 'negative' || rating <= 3) {
      setPositive(false)
    }
    return rating
  }

  const showPositive = positive === true && appointmentExists
  const showNegative = positive === false && appointmentExists
  const showLinks = urls && (urls.facebook_url || urls.google_url || urls.yelp_url)

  let headerText
  if (showPositive) {
    headerText = "We're happy everything went well! Feel free to share any comments about the experience!"
  } else if (showNegative) {
    headerText = "We're sorry your appointment didn't go as well as it should have. Please share any comments that you think would help us improve the experience."
  }

  return (
    <div className="outerWrapper">
      <div className="thankYou">
        Thank you for your survey!
      </div>
      <div className="innerWrapper">
        <p className="headerText">
          {headerText}
        </p>
        {appointmentExists && (
          <div className="comment">
            <CommentBox
              existingComment={existingComment}
            />
          </div>
        )}
        <div className="links">
          {showLinks && <SocialLinks urls={urls} />}
        </div>
      </div>
    </div>
  )
}

export default App
