import React, { useEffect, useState } from 'react'
import fetchApi from '../services/fetchApi'
import PropTypes from 'prop-types'
import './styles/comment-box.css'

const CommentBox = ({ existingComment }) => {
  const [comment, setComment] = useState('')
  const [commentPosted, setCommentPosted] = useState(false)

  useEffect(() => {
    if (existingComment) setComment(existingComment)
  }, [existingComment])

  const postComment = async (comment) => {
    const appointmentId = document.location.pathname.split("/")[1]
    const body = JSON.stringify({ comment })
    const response = await fetchApi(`/review/${appointmentId}/comment`, 'POST', body)
    if (response.comment) setCommentPosted(true)
  }

  const handleOnClick = () => {
    postComment(comment)
  }

  const updateComment = (event) => {
    const newComment = event.target.value
    setComment(newComment)
  }

  const validComment = () => {
    if (!comment || !comment.length || comment.length > 500) return false
    return true
  }

  const textareaDisabled = commentPosted || !!existingComment
  const submitDisabled = !validComment() || textareaDisabled

  return (
    <div className="commentBox">
      <textarea
        value={comment}
        onChange={updateComment}
        disabled={textareaDisabled}
      />
      <button
        type="submit"
        onClick={handleOnClick}
        disabled={submitDisabled}
      >
        Submit
        {(!!existingComment || commentPosted) && (
          <span>✓</span>
        )}
      </button>
      {(!!existingComment || commentPosted) && (
        <p className="submitted">Your comments have been submitted.</p>
      )}
    </div>
  )
}

CommentBox.propTypes = {
  existingComment: PropTypes.string,
  commentPosted: PropTypes.bool.isRequired,
  setCommentPosted: PropTypes.func.isRequired,
}

export default CommentBox
